export const custom = {
  /**
  * Idiomas disponibles ['es','en','pt','cat']
  */
  availableLangs: ['es'],

  /**
   * Idioma por defecto: ['es']
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: '6ef2c4b3-e9a6-4201-a98b-4603cedb78a5',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'equilibrium-cm.alares',

  /**
  * Nombre de la app
  */
  appName: 'Planeta Equilibrium',

  /**
  * Código de analytics 'G-XXXXXXXXXXX'
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-F6QP3V6CTF',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: true,

  /** Registro habilitado o deshabilitado */
  registration: true,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true,
  
  videoCallsUrl: 'https://videollamada.vivofacil.com/?kid=AljJRe39SLEsWhMFg2pqTrA',
  /**
   * Extensión en producción de URL de la app
   * (es, com, org...)
   */
   appUrlTld: 'es',

  /**
   * Nombre de marca interno (Alares, Vivofácil...)
   */
   brandName: 'Alares',
   
   /**
   * Mail transport (alares, vivofacil...)
   */
   mailTransport: 'alares'
};
